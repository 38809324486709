import { createContext } from "react";

import { ShopConfig } from "@utils/ssr";

const defaultCountry = {
  __typename: "CountryDisplay" as "CountryDisplay",
  code: "AU",
  country: "Australia",
};

export type ShopProviderType = {
  currency: string;
  demonym: string;
};

export const defaultContext: ShopConfig["shopConfig"] &
  ShopConfig["shop"] &
  ShopProviderType = {
  __typename: "Shop",
  countries: [],
  defaultCountry,
  displayGrossPrices: true,
  saleBannerMessage: null,
  name: "The Volte",
  marketplace: true,
  language: "en-AU",
  domain: {
    url: "https://thevolte.com",
    host: "thevolte.com",
    __typename: "Domain",
  },
  currency: "AU",
  demonym: "Australian",
  operatingHours: [],
  samedayOrNextdayPickupDistance: "25",
};

export const ShopContext = createContext<
  ShopConfig["shopConfig"] & ShopConfig["shop"] & ShopProviderType
>(defaultContext);

ShopContext.displayName = "ShopContext";
