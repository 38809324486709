import { siteUrl } from "./constants";

const baseUrl = "/";
const slugUrl = ":slug";
const accountBaseUrl = `${baseUrl}account/`;
const checkoutBaseUrl = `${baseUrl}checkout/`;
const pageBaseUrl = `${baseUrl}page/`;
const categoryBaseUrl = `${baseUrl}category/`;
const collectionBaseUrl = `${baseUrl}collection/`;

export const paths = {
  notFound: `${baseUrl}404`,
  accountConfirm: `${baseUrl}account-confirm`,
  cart: `${baseUrl}cart`,
  categoryBase: `${categoryBaseUrl}`,
  category: `${categoryBaseUrl}${slugUrl}`,
  dresses: `${baseUrl}${slugUrl}`,
  collectionBase: `${collectionBaseUrl}`,
  collection: `${collectionBaseUrl}${slugUrl}`,
  guestOrderDetail: `${baseUrl}order-history/:token`,
  home: baseUrl,
  login: `${baseUrl}login`,
  processingpayment: `${baseUrl}processing-payment`,
  orderFinalized: `${baseUrl}order-finalized`,
  pageBase: `${pageBaseUrl}`,
  page: `${pageBaseUrl}:slug`,
  passwordReset: `${baseUrl}reset-password`,
  product: `${baseUrl}item/${slugUrl}`,
  search: `${baseUrl}search`,
  defaultCategory: `${baseUrl}dresses`,
  orderRating: `${baseUrl}review/rating`,
  /**
   * Checkout
   */
  checkout: checkoutBaseUrl,
  checkoutAddress: `${checkoutBaseUrl}address`,
  checkoutPayment: `${checkoutBaseUrl}payment`,
  checkoutPaymentConfirm: `${checkoutBaseUrl}payment-confirm`,
  checkoutReview: `${checkoutBaseUrl}review`,
  checkoutShipping: `${checkoutBaseUrl}shipping`,
  /**
   * Account section
   */
  account: accountBaseUrl,
  accountAddressBook: `${accountBaseUrl}address-book`,
  wishlist: `${accountBaseUrl}wishlist`,
  // FIXME: User order should be accessible via order id
  accountOrderDetail: `${accountBaseUrl}order-history/:token`,
  accountOrderHistory: `${accountBaseUrl}order-history`,
  accountOrderOverview: `${accountBaseUrl}order-history/:token/overview`,
  dashboard: `${siteUrl}/dashboard/`,
  accountMessages: `${accountBaseUrl}messages`,
  accountLending: `${siteUrl}/dashboard/`,
  accountLendingProducts: `${siteUrl}/dashboard/products`,

  /**
   * Designers
   */
  designers: `${baseUrl}designers`,
  designer: `${baseUrl}designers/${slugUrl}`,

  /**
   * Promo pages
   */
  partnerPromo: `${baseUrl}wear-the-dress-share-the-dress`,

  /**
   * Partner portal
   */
  partners: `${siteUrl}/partners/`,
};
