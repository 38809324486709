import { ProductOrder } from "@the-volte/svc-core-sdk";

import { MenuItem } from "@graphql/gqlTypes/MenuItem";
import { channelSlug } from "@temp/constants";
import { OrderDirection, ProductOrderField } from "@types/global";

import { IFilterAttributes } from "../types";

interface AttributeDict {
  [attributeSlug: string]: string[];
}

export function convertToAttributeScalar(
  attributes: AttributeDict | IFilterAttributes
) {
  const attrs = {};
  Object.entries(attributes).forEach(([key, value]) => {
    if (!attrs[key]) {
      attrs[key] = [];
    }
    attrs[key].push(value);
  });
  return Object.entries(attrs)
    .map(([key, value]) =>
      value.map((attribute: any) => ({ slug: key, values: attribute }))
    )
    .reduce((prev, curr) => [...prev, ...curr], []);
}

export const convertSortByFromString = (
  sortBy: string,
  channel = channelSlug
): ProductOrder | null => {
  if (!sortBy) {
    return null;
  }

  const direction = sortBy.startsWith("-")
    ? OrderDirection.DESC
    : OrderDirection.ASC;

  let field;

  switch (sortBy.replace(/^-/, "")) {
    case "price":
      field = ProductOrderField.PRICE;
      break;

    case "created_at":
      field = ProductOrderField.CREATED_AT;
      break;

    default:
      return null;
  }
  return { field, direction, channel };
};

export function snakeToCamel(obj: any): object | any {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => snakeToCamel(item));
  }

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase()),
      snakeToCamel(value),
    ])
  );
}

export const getLinkMenuItem = (menuItem?: MenuItem | null | any) => {
  if (!menuItem) {
    return "";
  }

  const { url } = menuItem;

  if (url) {
    return url;
  }

  return "";
};
